import "../styles/index.scss"
import React from "react"
// import Header from '../components/header';
import Header from "../components/HeaderConfirm"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import { Link } from "gatsby"

const FAQ = ({ location }) => {
  return (
    <React.Fragment>
      <SEO title="FAQ" description="Frequently Asked Questions" />
      <Header path={location.pathname} />
      <div className="faq-container">
        <div className="faq-section-container">
          <div className="faq-title">Frequently Asked Questions</div>

          {/* <div className="faq-section-description">
            This FAQ Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            catbear.love (the “Site”).{" "}
          </div> */}
          {/* <div className="faq-section-description">By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following faq and conditions (“faq of Service”, “faq”), including those additional faq and conditions and policies referenced herein and/or available by hyperlink. These faq of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</div>
        <div className="faq-section-description">Please read these faq of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these faq of Service. If you do not agree to all the faq and conditions of this agreement, then you may not access the website or use any services. If these faq of Service are considered an offer, acceptance is expressly limited to these faq of Service.</div>
        <div className="faq-section-description">Any new features or tools which are added to the current store shall also be subject to the faq of Service. You can review the most current version of the faq of Service at any time on this page. We reserve the right to update, change or replace any part of these faq of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</div>
        <div className="faq-section-description">Our store uses Shopify Inc. to accept payments. They provide us with the online e-commerce platform that allows us to sell our products and services to you. </div> */}
          {/* <div className="faq-section-description"></div>
        <div className="faq-section-description"></div>
        <div className="faq-section-description"></div>
        <div className="faq-section-description"></div> */}
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">
            What is the purpose of each journal, and which journal is best for
            my child?
          </div>
          <div className="faq-section-description">
            <b>Gratitude Journals:</b>
          </div>
          <div className="faq-section-description">
            Our gratitude journal is great for laying a foundation of
            perspective early on in life. A perspective that ultimately
            cultivates a long term positive outlook.
          </div>
          <div className="faq-section-description">
            Moreover, we found that for many of our customers, the gratitude
            journal serves as a gateway for consistent and positive discussions
            as your child reveals the various events throughout their day.
          </div>
          <div className="faq-section-description">
            <b>Discovery Pocket Book:</b>
          </div>
          <div className="faq-section-description">
            For our children, the world is still very new, and the outdoors can
            be an exhilarating place to navigate. This can be especially true if
            the goal of the activity itself was about exploration and discovery.
          </div>
          <div className="faq-section-description">
            As you can imagine, this is another great one for the entire family.
          </div>

          <div className="faq-section-description">
            <b>Drawing Pads:</b>
          </div>
          <div className="faq-section-description">
            The act and practice of creating cultivates creativity in the purest
            sense.
          </div>
          <div className="faq-section-description">
            The drawing pad itself is constructed with a large landscape canvas
            to accommodate the boldest of creations.
          </div>
          <div className="faq-section-description">
            Crayons and color pencils work great with the drawing pad. The
            effect of pens, markers, and the like will vary greatly in density
            and thus may bleed through the page if the ink is heavy enough.
          </div>

          <div className="faq-section-description">
            <b>Time Capsule:</b>
          </div>
          <div className="faq-section-description">
            As we parents know, time goes by blazingly fast. Embark on this 3
            year journey with your child and relive precious moments from the
            years prior.
          </div>
          <div className="faq-section-description">
            All questions fall into one or more of the following categories:
            fun, quirky, and charming.
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">
            What is the quality of these journals?
          </div>
          <div className="faq-section-description">
            Our paper is FSC certified, lead-free, acid-free, buffered paper
            made from wood-based pulp. The FSC certification ensures that
            products come from responsibly managed forests that provide
            environmental, social and economic benefits.
          </div>
          <div className="faq-section-description">
            Our covers are vibrant in color and super durable as well, able to
            withstand a high degree of wear and tear.
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">
            How long do the journals last?
          </div>
          {/* <div className="faq-section-description">
            <b>1 month = 30 days</b>
          </div> */}
          <div className="faq-section-description">
            <b>Gratitude Journals</b>: 4 months
          </div>
          <div className="faq-section-description">
            {/* <b>Drawing Pads</b>: 3 months and 10 days */}
            <b>Drawing Pads</b>: 3+ months
          </div>
          <div className="faq-section-description">
            {/* <b>Discovery Pocket Book</b>: 3 months and 10 days */}
            <b>Discovery Pocket Book</b>: 3+ months
          </div>
          <div className="faq-section-description">
            {/* <b>Time Capsule</b>: 3 years */}
            <b>Time Capsule</b>: 3 years
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">
            I still have a question, how can I contact you?
          </div>
          <div className="faq-section-description">
            We'd be happy to hear from you. Please feel free to send us an email
            at <span style={{ color: "#22B2F2" }}>hello@catbear.love</span>, or
            you can fill out the{" "}
            <Link style={{ color: "#22B2F2" }} to="/contact">
              contact form
            </Link>{" "}
            and we will get back to you as soon as we can.
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">Where do orders ship from?</div>
          <div className="faq-section-description">
            Our production partners are based in France, Poland, Australia,
            Canada, the United Kingdom, and the United States.
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">Where do you ship?</div>
          <div className="faq-section-description">We ship worldwide!</div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">Shipping Time</div>
          <div className="faq-section-description">
            {/* United States: 10 - 12 business days. */}
            United States: 8 - 15 business days.
          </div>
          <div className="faq-section-description">
            {/* Canada: 14 - 16 business days. */}
            Canada: 10 - 19 business days.
          </div>
          {/* <div className="faq-section-description">
            Great Britain: 6 - 8 business days.
          </div> */}
          <div className="faq-section-description">
            {/* France: 9 - 11 business days. */}
            United Kingdom: 5 - 8 business days.
          </div>
          <div className="faq-section-description">
            {/* France: 9 - 11 business days. */}
            France: 5 - 11 business days.
          </div>
          <div className="faq-section-description">
            Germany: 5 - 8 business days.
          </div>
          <div className="faq-section-description">
            Spain: 6 - 11 business days.
          </div>
          <div className="faq-section-description">
            {/* Australia: 14 - 16 business days. */}
            Australia: 10 - 19 business days.
          </div>
          <div className="faq-section-description">
            {/* New Zealand: 14 - 16 business days. */}
            New Zealand: 10 - 19 business days.
          </div>
          <div className="faq-section-description">
            Asia: 7 - 16 business days.
          </div>

          <div className="faq-section-description">
            Sweden: 6 - 13 business days.
          </div>
          <div className="faq-section-description">
            Middle East: 6 - 19 business days.
          </div>
          <div className="faq-section-description">
            Africa: 7 - 22 business days.
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">
            Are there any countries exempt from delivery?
          </div>
          <div className="faq-section-description">
            Because Fedex is one of our primary carriers, we do not ship to
            countries that do not support FedEx. For a full list of countries,
            please{" "}
            <a
              style={{ color: "#22B2F2" }}
              target="_blank"
              href="http://www.fedex.com/be/contact/served-countries.html"
            >
              click here
            </a>
            .
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">
            How do I contact Cat & Bear for any issues?
          </div>
          <div className="faq-section-description">
            <div>
              1. Use the{" "}
              <Link style={{ color: "#22B2F2" }} to="/contact">
                contact form
              </Link>{" "}
              to reach out to us.{" "}
            </div>
            <div>
              2. We'll reach back out through email, and we will ask for a few
              good quality pictures that clearly illustrate the problem.{" "}
            </div>
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">Returns & Cancellations</div>
          {/* <div className="faq-section-description">All returns are handled on a case by case basis.</div> */}
          <div className="faq-section-description">
            The cancellation requests are accepted no later than 30 minutes
            after the order placement. No Order may be canceled or altered by
            the Customer after it has already been shipped except with Cat &
            Bear's written consent.
          </div>
          <div className="faq-section-description">
            If there is a defective product involved, we will either replace
            immediately or in some cases process a refund. This requires that
            you reach out to us with the appropriate evidence within 15 days of
            order. Please attach a digital image showing the problem with the
            order. No need to send the journal back. To get this process
            started, you can email us at{" "}
            <span style={{ color: "#22B2F2" }}>hello@catbear.love</span>
          </div>
        </div>

        <div className="faq-section-container">
          <div className="faq-section-title">Your rights </div>
          <div className="faq-section-description">
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.{" "}
          </div>
          <div className="faq-section-description">
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.{" "}
          </div>
        </div>
        <div className="faq-section-container">
          <div className="faq-section-title">Data retention </div>
          <div className="faq-section-description">
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.{" "}
          </div>
          <div className="faq-section-description">
            {" "}
            We also use Google Analytics that is fully GDPR compiant.
          </div>
          <div className="faq-section-description">
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </div>
          <div className="faq-section-description">
            You can opt out of targeted advertising by using the links below:
            <br />
            - Facebook: https://www.facebook.com/settings/?tab=ads
            <br />
            - Google: https://www.google.com/settings/ads/anonymous
            <br />
            - Bing:
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
            <br />
            Additionally, you can opt out of some of these services by visiting
            the Digital Advertising Alliance’s opt-out portal at:
            http://optout.aboutads.info/.
          </div>
        </div>
        <div className="faq-section-container">
          <div className="faq-section-title">Changes</div>
          <div className="faq-section-description">
            We may update this faq policy from time to time in order to reflect,
            for example, changes to our practices or for other operational,
            legal or regulatory reasons.{" "}
          </div>
        </div>
        {/* <div className="faq-section-container">
					<div className="faq-section-title">Minors</div>
					<div className="faq-section-description">
						The Site is not intended for individuals under the age of 18.
					</div>
				</div> */}
        <div className="faq-section-container">
          <div className="faq-section-title">Contact Us</div>
          <div className="faq-section-description">
            For more information about our faq practices, if you have questions,
            or if you would like to make a complaint, please contact us by
            e‑mail at{" "}
            <span style={{ color: "#22B2F2" }}>hello@catbear.love</span>.
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default FAQ
